export const getMFAConfig = clientDetails => {
  if (!clientDetails?.metadata?.authentication) return undefined;
  const config = {};
  const clientAuthConfig = clientDetails?.metadata?.authentication;
  if (clientAuthConfig?.mfaLockoutMinutes)
    config.lockoutDuration = clientAuthConfig.mfaLockoutMinutes * 60 * 1000;
  if (clientAuthConfig?.mfaMaxCodeAttempts)
    config.maxAttempts = clientAuthConfig.mfaMaxCodeAttempts;
  if (clientAuthConfig?.mfaCodeExpiryMinutes)
    config.codeExpiry = clientAuthConfig.mfaCodeExpiryMinutes * 60 * 1000;
  if (clientAuthConfig?.mfaResendCodeSeconds)
    config.resendCooldown = clientAuthConfig.mfaResendCodeSeconds;
  if (typeof clientAuthConfig?.mfaRevalidationDays === 'number')
    config.revalidationPeriod = clientAuthConfig.mfaRevalidationDays;
  return Object.keys(config).length ? config : undefined;
};

export const getMFACooldownTime = clientDetails =>
  clientDetails?.metadata?.authentication?.mfaResendCodeSeconds ?? 60;
