/*
 *
 * Auth actions
 *
 */

import { createAction } from 'redux-actions';
import {
  DEFAULT_ACTION,
  PROCESSING,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  CHECK_EMAIL_AVAILABILITY,
  CHECK_EMAIL_AVAILABILITY_RESULT,
  COMPLETE_SIGN_UP,
  COMPLETE_SIGN_UP_SUCCESS,
  COMPLETE_SIGN_UP_FAIL,
  LOGIN_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGOUT,
  AUTH_ERROR,
  STORE_ITEM_FOR_SIGN_IN,
  SHOW_SUBSCRIPTION_MODAL,
  HIDE_SUBSCRIPTION_MODAL,
  SUBSCRIPTION_PROCESSING,
  SUBMIT_SUBSCRIPTION,
  SUBMIT_SUBSCRIPTION_RESULT,
  GET_SUBSCRIPTION_STATUS,
  GET_SUBSCRIPTION_STATUS_RESULT,
  UPDATE_SUBSCRIPTION_STATUS,
  UPDATE_SUBSCRIPTION_STATUS_RESULT,
  SET_AUTH_MODAL_STATE,
  SET_AUTH_MODAL_ACTION,
  SHOW_AUTH_CTA_MODAL,
  HIDE_AUTH_CTA_MODAL,
  SET_HERO_VARIANT,
  GET_PROFILE_FILTERS,
  SET_PROFILE_FILTERS,
  REGENERATE_ANONYMOUS_ID,
  SET_2FA_COMPLETED,
} from './constants';

export const defaultAction = createAction(DEFAULT_ACTION);
export const processing = createAction(PROCESSING);
export const incrementSignUpCount = createAction(
  'app/Auth/INCREMENT_SIGN_UP_COUNT',
);
export const signUp = createAction(SIGN_UP);
export const signUpSuccess = createAction(SIGN_UP_SUCCESS);
export const signUpFail = createAction(SIGN_UP_FAIL);
export const checkEmailAvailability = createAction(CHECK_EMAIL_AVAILABILITY);
export const checkEmailAvailabilityResult = createAction(
  CHECK_EMAIL_AVAILABILITY_RESULT,
);
export const completeSignUp = createAction(COMPLETE_SIGN_UP);
export const completeSignUpSuccess = createAction(COMPLETE_SIGN_UP_SUCCESS);
export const completeSignUpFail = createAction(COMPLETE_SIGN_UP_FAIL);
export const login = createAction(LOGIN);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginFail = createAction(LOGIN_FAIL);
export const logout = createAction(LOGOUT);
export const authError = createAction(AUTH_ERROR);
export const storeItemForSignIn = createAction(STORE_ITEM_FOR_SIGN_IN);
export const showSubscriptionModal = createAction(SHOW_SUBSCRIPTION_MODAL);
export const hideSubscriptionModal = createAction(HIDE_SUBSCRIPTION_MODAL);
export const subscriptionProcessing = createAction(SUBSCRIPTION_PROCESSING);
export const submitSubscription = createAction(SUBMIT_SUBSCRIPTION);
export const submitSubscriptionResult = createAction(
  SUBMIT_SUBSCRIPTION_RESULT,
);
export const getSubscriptionStatus = createAction(GET_SUBSCRIPTION_STATUS);
export const getSubscriptionStatusResult = createAction(
  GET_SUBSCRIPTION_STATUS_RESULT,
);
export const updateSubscriptionStatus = createAction(
  UPDATE_SUBSCRIPTION_STATUS,
);
export const updateSubscriptionStatusResult = createAction(
  UPDATE_SUBSCRIPTION_STATUS_RESULT,
);
export const setAuthModalState = createAction(SET_AUTH_MODAL_STATE);
export const setAuthModalAction = createAction(SET_AUTH_MODAL_ACTION);
export const showAuthCTAModal = createAction(SHOW_AUTH_CTA_MODAL);
export const hideAuthCTAModal = createAction(HIDE_AUTH_CTA_MODAL);
export const setHeroVariant = createAction(SET_HERO_VARIANT);
export const getProfileFilters = createAction(GET_PROFILE_FILTERS);
export const setProfileFilters = createAction(SET_PROFILE_FILTERS);
export const regenerateAnonymousId = createAction(REGENERATE_ANONYMOUS_ID);
export const set2FACompleted = createAction(SET_2FA_COMPLETED);
