import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { isLocalStorageAllowed } from 'utils/stringUtils';
import {
  processing,
  getClientDetailsSuccess,
  getClientDetailsFail,
  showTermsOfUse,
  showPrivacyPolicy,
  showFeedback,
  sendMailProcessing,
  sendMail,
  sendMailSuccess,
  getSiteConfigSuccess,
  getSiteCopySuccess,
  setAudienceTagsRelations,
  clientDetailsFetching,
  getProgramsByTopicSuccess,
  getThemesSuccess,
  getClientDetails,
  getResourcesCountByTopicSuccess,
  getClientResourcesSuccess,
  showRegionSelector,
  setRegion,
  showRegionBanner,
  clearClientDetails,
  setLandingMenu,
  showHomepagePro,
  setNPSVisibility,
  incrementNPSPlayCount,
  setFreshworksLoaded,
  meetNPSPreconditions,
  setHelpfulLocation,
  saveRemindMeLaterResourceResult,
  getRemindMeLaterResourceResult,
  getPageIntrosResult,
  getReferralsResult,
  setLanguage,
  showLanguageBanner,
  setLocales,
  setLeavingModal,
  setAICCUser,
  setAICCRedirected,
  setClinicMode,
  showOnboardingQuestions,
  showInsuranceModal,
  hideInsuranceModal,
  showPersonalEmailModal,
  hidePersonalEmailModal,
  setFirestoreStatus,
  setContentBlocks,
  setIsEndingSession,
  showFloatingHelpfulAgain,
  updateClientDetails,
  showAbout,
  setTextDirection,
  getHomeReferralsResult,
  setEmailValidationBanner,
} from './actions';
import {
  getInitialLanguageCode,
  setLanguageToLocalStorage,
  languageCodeMapping,
} from './utils';

export const initialState = {
  processing: false,
  clientDetails: null,
  clientDetailsFetching: false,
  clientDetailsLoaded: false,
  error: {},
  showingTermsOfUse: false,
  showingPrivacyPolicy: false,
  showingAbout: false,
  showingFeedback: false,
  mailSent: false,
  sendMailProcessing: false,
  isFreshworksLoaded: false,
  siteConfig: [],
  siteCopy: [],
  siteCopyLanguage: null,
  siteCopyInOtherLang: {},
  searchCopyInOtherLang: {},
  audienceTagsRelations: {},
  programs: {},
  themes: [],
  resourcesCountByTopic: [],
  clientResources: [],
  aiccUser: {},
  aiccRedirected: false,
  showRegionSelector: false,
  region: (isLocalStorageAllowed() && localStorage.getItem('region')) || '',
  showRegionBanner: false,
  landingMenu: null,
  showHomepagePro: false,
  npsVisibility: {
    visibility: false,
    reason: null,
  },
  npsPlayCount: 0,
  npsPreconditions: false,
  helpfulLocation: '',
  leavingModal: {
    visible: false,
    title: null,
    url: null,
    organization: null || '',
    resourceType: null || '',
    articleURL: null || '',
    leavingModal: false,
  },
  insuranceModal: {
    visible: null,
    type: null,
    resourceType: null,
  },
  personalEmailModal: {
    visible: null,
    type: null,
  },
  saveRemindMeResourceResult: {},
  getRemindMeResourceResult: {},
  pageIntros: [],
  referrals: [],
  homeReferrals: [],
  showLanguageBanner: false,
  language: getInitialLanguageCode(),
  locales: [],
  clinicMode: null,
  showOnboardingQuestions: false,
  firestoreStatus: true,
  contentBlocks: [],
  showFloatingHelpfulAgain: true,
  isEndingSession: false,
  emailValidationBanner: true,
};

const mainReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [clientDetailsFetching](state, action) {
      return update(state, { clientDetailsFetching: { $set: action.payload } });
    },
    [clearClientDetails](state) {
      return update(state, {
        clientDetails: { $set: {} },
        clientDetailsLoaded: { $set: true },
      });
    },
    [getClientDetails](state) {
      return update(state, {
        // clientDetailsFetching: { $set: true },
        clientDetails: { $set: {} },
        clientDetailsLoaded: { $set: false },
      });
    },
    [getClientDetailsSuccess](state, action) {
      return update(state, {
        clientDetails: {
          $set: action.payload,
        },
        clientDetailsLoaded: { $set: true },
        // clientDetailsFetching: { $set: false },
      });
    },
    [getClientDetailsFail](state, action) {
      return update(state, {
        error: { $set: action.payload },
        clientDetailsLoaded: { $set: true },
      });
    },
    [showTermsOfUse](state, action) {
      return update(state, { showingTermsOfUse: { $set: action.payload } });
    },
    [setFreshworksLoaded](state, action) {
      if (state.isFreshworksLoaded === action.payload) return state;

      return update(state, { isFreshworksLoaded: { $set: action.payload } });
    },
    [setTextDirection](state, action) {
      if (state.textDirection === action.payload) return state;

      return update(state, { textDirection: { $set: action.payload } });
    },
    [showAbout](state, action) {
      return update(state, { showingAbout: { $set: action.payload } });
    },
    [showPrivacyPolicy](state, action) {
      return update(state, { showingPrivacyPolicy: { $set: action.payload } });
    },
    [showFeedback](state, action) {
      return update(state, { showingFeedback: { $set: action.payload } });
    },
    [sendMailProcessing](state, action) {
      return update(state, { sendMailProcessing: { $set: action.payload } });
    },
    [sendMail](state) {
      return update(state, { mailSent: { $set: false } });
    },
    [sendMailSuccess](state) {
      return update(state, { mailSent: { $set: true } });
    },
    [getSiteConfigSuccess](state, action) {
      return update(state, {
        siteConfig: { $set: action.payload.items },
        featuresConfig: { $set: action.payload.featuresConfig },
      });
    },
    [getSiteCopySuccess](state, action) {
      return update(state, {
        siteCopy: { $set: action.payload.items },
        siteCopyLanguage: { $set: action.payload.language },
        siteCopyInOtherLang: {
          $set: action.payload.otherLanguageResourceItemPage,
        },
        searchCopyInOtherLang: { $set: action.payload.otherLanguageSearch },
      });
    },
    [getProgramsByTopicSuccess](state, action) {
      return update(state, { programs: { $set: action.payload } });
    },
    [getThemesSuccess](state, action) {
      return update(state, { themes: { $set: action.payload } });
    },
    [getResourcesCountByTopicSuccess](state, action) {
      return update(state, { resourcesCountByTopic: { $set: action.payload } });
    },
    [getClientResourcesSuccess](state, action) {
      return update(state, { clientResources: { $set: action.payload } });
    },
    [showRegionSelector](state, action) {
      return update(state, { showRegionSelector: { $set: action.payload } });
    },
    [setRegion](state, action) {
      if (isLocalStorageAllowed()) {
        localStorage.setItem('region', action.payload);
      }
      return update(state, { region: { $set: action.payload } });
    },
    [showRegionBanner](state, action) {
      return update(state, { showRegionBanner: { $set: action.payload } });
    },
    [setLandingMenu](state, action) {
      return update(state, { landingMenu: { $set: action.payload } });
    },
    [showHomepagePro](state, action) {
      return update(state, { showHomepagePro: { $set: action.payload } });
    },
    [setAudienceTagsRelations](state, action) {
      return update(state, { audienceTagsRelations: { $set: action.payload } });
    },
    [setNPSVisibility](state, action) {
      if (
        state.npsVisibility.reason === 'user-close' ||
        state.npsVisibility.reason === 'user-submit' ||
        (action.payload.reason !== 'user-close' &&
          action.payload.reason !== 'user-submit' &&
          state.npsVisibility.reason &&
          state.npsVisibility.reason !== action.payload.reason)
      )
        return state;

      const visibility = state.npsPreconditions
        ? action.payload.visibility
        : false;
      return update(state, {
        npsVisibility: {
          visibility: { $set: visibility },
          reason: { $set: action.payload.reason },
        },
      });
    },
    [incrementNPSPlayCount](state) {
      return update(state, { npsPlayCount: { $set: state.npsPlayCount + 1 } });
    },
    [meetNPSPreconditions](state) {
      if (
        state.npsVisibility.reason &&
        state.npsVisibility.reason !== 'user-close' &&
        state.npsVisibility.reason !== 'user-submit'
      ) {
        return update(state, {
          npsPreconditions: { $set: true },
          npsVisibility: { visibility: { $set: true } },
        });
      }

      return update(state, { npsPreconditions: { $set: true } });
    },
    [setHelpfulLocation](state, action) {
      return update(state, { helpfulLocation: { $set: action.payload } });
    },
    [showFloatingHelpfulAgain](state, action) {
      return update(state, {
        showFloatingHelpfulAgain: { $set: action.payload },
      });
    },
    [saveRemindMeLaterResourceResult](state, action) {
      return update(state, {
        saveRemindMeResourceResult: { $set: action.payload },
      });
    },
    [getRemindMeLaterResourceResult](state, action) {
      return update(state, {
        getRemindMeResourceResult: { $set: action.payload },
      });
    },
    [getPageIntrosResult](state, action) {
      return update(state, {
        pageIntros: { $set: action.payload },
      });
    },
    [getReferralsResult](state, action) {
      return update(state, {
        referrals: {
          $set: action.payload,
        },
      });
    },
    [getHomeReferralsResult](state, action) {
      return update(state, {
        homeReferrals: {
          $set: action.payload,
        },
      });
    },
    [setLanguage](state, action) {
      const finalLanguageShortCode =
        languageCodeMapping[action.payload] || action.payload;
      if (state.language === finalLanguageShortCode) return state;

      setLanguageToLocalStorage(finalLanguageShortCode);
      return update(state, { language: { $set: finalLanguageShortCode } });
    },
    [showLanguageBanner](state, action) {
      return update(state, { showLanguageBanner: { $set: action.payload } });
    },
    [setLocales](state, action) {
      return update(state, { locales: { $set: action.payload } });
    },
    [setLeavingModal](state, action) {
      return update(state, { leavingModal: { $set: action.payload } });
    },
    [setAICCUser](state, action) {
      return update(state, { aiccUser: { $set: action.payload } });
    },
    [setAICCRedirected](state, action) {
      return update(state, { aiccRedirected: { $set: action.payload } });
    },
    [setClinicMode](state, action) {
      if (state.clinicMode !== action.payload)
        return update(state, { clinicMode: { $set: action.payload } });
      return state;
    },
    [showOnboardingQuestions](state, action) {
      return update(state, {
        showOnboardingQuestions: { $set: action.payload },
      });
    },
    [showInsuranceModal](state, action) {
      if (!state.insuranceModal.visible)
        return update(state, {
          insuranceModal: {
            $set: {
              visible: true,
              type: action.payload.type,
              resourceType: action.payload.resourceType,
            },
          },
        });
      return state;
    },
    [hideInsuranceModal](state) {
      if (state.insuranceModal.visible !== false)
        return update(state, {
          insuranceModal: {
            $set: { visible: false, type: null, resourceType: null },
          },
        });
      return state;
    },
    [showPersonalEmailModal](state, action) {
      if (!state.personalEmailModal.visible)
        return update(state, {
          personalEmailModal: {
            $set: {
              visible: true,
              type: action.payload.type,
            },
          },
        });
      return state;
    },
    [hidePersonalEmailModal](state) {
      if (state.personalEmailModal.visible !== false)
        return update(state, {
          personalEmailModal: {
            $set: { visible: false, type: null },
          },
        });
      return state;
    },
    [setFirestoreStatus](state, action) {
      return update(state, {
        firestoreStatus: {
          $set: action.payload,
        },
      });
    },
    [setContentBlocks](state, action) {
      return update(state, {
        contentBlocks: {
          $set: action.payload,
        },
      });
    },
    [setIsEndingSession](state, action) {
      if (state.isEndingSession !== action.payload)
        return update(state, { isEndingSession: { $set: action.payload } });
      return state;
    },
    [updateClientDetails](state, action) {
      return update(state, {
        clientDetails: {
          $set: action.payload,
        },
      });
    },
    [setEmailValidationBanner](state, action) {
      return update(state, {
        emailValidationBanner: {
          $set: action.payload,
        },
      });
    },
  },

  initialState,
);

export default mainReducer;
