/**
 *
 * Routes
 *
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import NotFoundPage from 'components/EmptyState';
import UnavailablePage from 'components/UnavailableState';
import InactivePage from 'components/InactiveState';
import { isLocalhost } from 'utils/networkUtils';
import { windowScrollTo } from 'utils/embedded';
import use2FACheck from 'components/Hooks/use2FACheck';
import LoadingOverlay from 'components/LoadingOverlay';
import PrivateRoute from './PrivateRoute';
import RoutesList from './Routes';
import { PreviousLocationContext } from './PrevLocationContext';

const Routes = ({ location, clientDetails }) => {
  const prevLocationRef = useRef(location);
  const { isLoading } = use2FACheck(location?.pathname);

  const trackPage = page => {
    if (!isLocalhost) {
      setTimeout(() => {
        ReactGA4.set({ page });
        ReactGA4.send({ hitType: 'pageview', page });
      }, 2500);
    }
  };

  useEffect(() => {
    trackPage(location.pathname);
  }, []);

  useEffect(() => {
    const { pathname } = location;
    const prevPathname = prevLocationRef.current.pathname;

    if (pathname !== prevPathname) {
      if (
        !pathname.match(/assessments\/(.+)\/page\d/) ||
        pathname.replace(
          /assessments\/(.+)\/page\d/,
          (_, p1) => `assessments/${p1}`,
        ) !==
          prevPathname.replace(
            /assessments\/(.+)\/page\d/,
            (_, p1) => `assessments/${p1}`,
          )
      ) {
        windowScrollTo(0, 0);
      }
      trackPage(pathname);
    }
    prevLocationRef.current = location;
  }, [location]);

  const signInRequired = _get(clientDetails, 'signIn') === 'Require';
  const isAICC = _get(clientDetails, 'aicc', false);
  const isAppEmbed = _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  const isClientActive = _get(clientDetails, 'activeClient') ?? true;

  if (isLoading) {
    return <LoadingOverlay show />;
  }

  if (!isClientActive) {
    return (
      <Switch>
        <Route component={InactivePage} />
      </Switch>
    );
  }

  return (
    <PreviousLocationContext.Provider value={prevLocationRef.current}>
      <Switch>
        {RoutesList.map(
          ({
            exact,
            path,
            component,
            ignoreRequire,
            authRequire,
            clientCheckPath,
          }) => {
            const isClientAllowed = _get(clientDetails, clientCheckPath, false);
            if (clientCheckPath && !isClientAllowed) {
              return null;
            }

            return (!ignoreRequire && signInRequired) || authRequire ? (
              <PrivateRoute
                exact={exact}
                path={path}
                key={path}
                component={component}
              />
            ) : (
              <Route
                exact={exact}
                path={path}
                component={component}
                key={path}
              />
            );
          },
        )}
        <Route exact path="/404" component={NotFoundPage} />
        {(isAICC || isAppEmbed) && (
          <Route exact path="/unavailable" component={UnavailablePage} />
        )}
        <Redirect from="/company" to="/?showcorp=1" />
        <Route from="*" component={NotFoundPage} />
      </Switch>
    </PreviousLocationContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  clientDetails: makeSelectClientDetails(),
});

Routes.propTypes = {
  clientDetails: PropTypes.object,
  location: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(Routes));
