import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 9999,
    color: '#01619B',
  },
});

export default function LoadingOverlay({ show }) {
  const classes = useStyles();

  if (show)
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return null;
}

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
};
