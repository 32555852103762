import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import differenceInYears from 'date-fns/differenceInYears';
import { setProfileFilters } from 'containers/Auth/actions';
import { makeSelectProfile } from 'containers/Auth/selectors';
import {
  insuranceOptionsSelector,
  makeSelectAudienceTagsRelations,
  makeSelectClientDetails,
} from 'containers/Main/selectors';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import { getFiltersFromProfile, parseConfig } from './utils';

function useProfileFilters() {
  const dispatch = useDispatch();
  const algoliaLocale = useAlgoliaLocale();
  const audienceTagsRelations = useSelector(makeSelectAudienceTagsRelations());
  const clientDetails = useSelector(makeSelectClientDetails());
  const profile = useSelector(makeSelectProfile());
  const insuranceOptions = useSelector(insuranceOptionsSelector);
  const [
    profileSiteConfig,
    insuranceSiteConfig,
    ageGenderSiteConfig,
  ] = useSiteConfigSelector([
    'Profile to Tags/Audiences',
    'Insurance',
    'Age and Gender Mappings',
  ]);

  useEffect(() => {
    if (profile.isLoaded) {
      const clientAudience = _get(clientDetails, 'audience');
      const clientCustomDemographics = _get(
        clientDetails,
        'context.customDemographics',
        {},
      );
      const clientShortName = _get(clientDetails, 'shortName');

      const enforcedInsuranceOptions = _get(
        insuranceSiteConfig,
        ['config', 'enforcedInsuranceTags', clientShortName],
        [],
      );

      const insuranceLabel = _get(insuranceSiteConfig, [
        'config',
        'label',
        algoliaLocale,
      ]);

      const mappedProfile = {
        ...profile,
      };
      if (profile.birthdayMonthYear) {
        mappedProfile.profileAge = differenceInYears(
          Date.now(),
          new Date(
            profile.birthdayMonthYear.split('-')[0],
            profile.birthdayMonthYear.split('-')[1],
          ),
        );
      }
      Object.keys(clientCustomDemographics).forEach(key => {
        const finalKey = key === 'age' ? 'dob' : key;

        const [option] = clientCustomDemographics[key].options.filter(
          item => item.value === profile[finalKey],
        );
        if (option) {
          mappedProfile[finalKey] = option.mapsTo || option.value;
        }
      });

      const audienceConfig = parseConfig(
        profileSiteConfig,
        'audience',
        clientDetails,
      );
      const tagConfig = parseConfig(profileSiteConfig, 'tag', clientDetails);
      const profileConfig = {
        audience: audienceConfig,
        tag: tagConfig,
      };

      const profileFilters = getFiltersFromProfile({
        profile: mappedProfile,
        insuranceOptions,
        enforcedInsuranceOptions,
        audienceTagsRelations,
        profileConfig,
        insuranceLabel,
        locale: algoliaLocale,
        isStateActive: _get(
          ageGenderSiteConfig,
          'config.stateOfResidence.clients',
          [],
        ).includes(clientShortName),
        clientAudience,
      });

      dispatch(
        setProfileFilters({
          ...profileFilters,
          insuranceOptions,
          isLoaded: true,
        }),
      );
    }
  }, [
    ageGenderSiteConfig,
    algoliaLocale,
    audienceTagsRelations,
    clientDetails,
    dispatch,
    insuranceOptions,
    insuranceSiteConfig,
    profile,
    profileSiteConfig,
  ]);
}

export default useProfileFilters;
