import { createSelector } from 'reselect';

/**
 * Direct selector to the auth state domain
 */

const selectFirebaseDomain = state => state.firebase;
const selectAuthDomain = state => state.auth;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Auth
 */

const makeSelectAuth = () =>
  createSelector(
    selectFirebaseDomain,
    substate => substate.auth,
  );
const makeSelectProfile = () =>
  createSelector(
    selectFirebaseDomain,
    substate => substate.profile,
  );
const makeSelectStoredItem = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.storedItem,
  );
const makeSelectProcessing = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.processing,
  );
const makeSelectError = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.error,
  );
const makeSelectSignUpCount = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.signUpCount,
  );
const makeSelectSubscriptionProcessing = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.subscriptionProcessing,
  );
const makeSelectSubscriptionModalStatus = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.subscriptionModalStatus,
  );
const makeSelectSubscriptionResult = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.subscriptionResult,
  );
const makeSelectSubscriptionStatus = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.subscriptionStatus,
  );
const makeSelectAuthModal = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.authModal,
  );
const makeSelectAuthModalAction = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.authModalAction,
  );
const makeSelectAuthCTAModalStatus = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.authCTAModalStatus,
  );
const makeSelectAuthCTAModalProvider = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.authCTAModalProvider,
  );
const makeSelectEmailAvailability = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.emailAvailability,
  );
const makeSelectHeroVariant = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.heroVariant,
  );
const makeSelectProfileFilters = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.profileFilters,
  );
const makeSelectAnonymousId = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.anonymousId,
  );
const makeSelect2FACompleted = () =>
  createSelector(
    selectAuthDomain,
    substate => substate.is2FACompleted,
  );

export {
  makeSelectAuth,
  makeSelectProcessing,
  makeSelectStoredItem,
  makeSelectSubscriptionProcessing,
  makeSelectSubscriptionModalStatus,
  makeSelectSubscriptionResult,
  makeSelectProfile,
  makeSelectSignUpCount,
  makeSelectError,
  makeSelectSubscriptionStatus,
  makeSelectAuthModal,
  makeSelectAuthModalAction,
  makeSelectAuthCTAModalStatus,
  makeSelectAuthCTAModalProvider,
  makeSelectEmailAvailability,
  makeSelectHeroVariant,
  makeSelectProfileFilters,
  makeSelectAnonymousId,
  makeSelect2FACompleted,
};
