import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeSelectAuth } from 'containers/Auth/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useSiteConfigSelector from 'components/useSiteConfigSelector';

/*
  useIsEmailVerified
  - verified returns a boolean value for when the email has been verified
  - requireVerification returns a boolean value for when the client is set to require;
    this prevents users from receiving assessment results email, writing a review and
    signing up to newsletter.
*/

export const getEmailVerificationEnabled = (
  config,
  clientShortName,
  clientGroupShortName,
) => {
  if (!_get(config, 'enabled')) return false;
  if (
    _get(config, 'clientsExclude', []).includes(clientShortName) ||
    _get(config, 'clientsExclude', []).includes(clientGroupShortName)
  )
    return false;
  if (
    !_isEmpty(_get(config, 'clientsInclude', [])) &&
    !_get(config, 'clientsInclude', []).includes(clientShortName) &&
    !_get(config, 'clientsInclude', []).includes(clientGroupShortName)
  )
    return false;

  return true;
};

export const isEmailVerified = auth =>
  _get(auth, 'providerData.0.providerId') !== 'password' ||
  _get(auth, 'emailVerified') === true;

const useIsEmailVerified = () => {
  const auth = useSelector(makeSelectAuth());
  const clientDetails = useSelector(makeSelectClientDetails());
  const [siteConfig] = useSiteConfigSelector(['Features']);

  const clientShortName = _get(clientDetails, 'shortName');
  const clientGroupShortName = _get(clientDetails, 'clientGroup.shortName');
  const emailValidationConfig = _get(siteConfig, 'config.emailValidation', {});
  const isEnabled = getEmailVerificationEnabled(
    emailValidationConfig,
    clientShortName,
    clientGroupShortName,
  );

  const verified = isEmailVerified(auth);
  const requireVerification = _get(clientDetails, 'signIn') === 'Require';

  return { verified, isEnabled, requireVerification };
};

export default useIsEmailVerified;
