/* eslint-disable no-empty */
import mixpanel from 'mixpanel-browser';
import { get } from 'utils/api';
import { getLocalData } from 'utils/localDataStore';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';
import { getFirebase } from 'react-redux-firebase';
import qs from 'qs';
import { emitter } from '@marvelapp/react-ab-test';
import GA4 from 'utils/ga4Service';
import Config from 'utils/getEnvConfig';
import {
  isBot,
  isLocalStorageAllowed,
  getParsedUserAgeInfo,
  getParentShareUrl,
} from 'utils/stringUtils';
import { isLocalhost } from 'utils/networkUtils';
import { getStore } from 'configureStore';
import { getSubBrand, isWebView } from 'utils/embedded';
import { getIsClientAdmin } from 'containers/Auth/utils';
import FirebaseAnalyticsService from './firebaseAnalyticsService';
import { getBasename, removeBasename } from './history';

const IP_LOOKUP_URL = 'https://cloudflare.com/cdn-cgi/trace';

const DEBUG = false;

export const injectAssessmentResultsABData = (
  props,
  shouldABTest,
  isVariantB,
) => {
  const eventProps = props;
  if (shouldABTest) {
    eventProps.assessmentResultsDesignVariant = isVariantB
      ? 'variantB'
      : 'variantA';
  }
  return eventProps;
};

function registerTouch() {
  const touchURL = document.URL;

  mixpanel.people.set_once('First Touch URL', touchURL);
  mixpanel.people.set('Last Touch URL', touchURL);
}

function getReferrerGroup() {
  let referrerGroup = 'Other';

  if (/((http(s)?)?:\/\/)?google.([^/?]*)/.test(document.referrer)) {
    const params = qs.parse(document.URL.split('?')[1]);
    if (params.utm_source === 'adwords') referrerGroup = 'Google Paid';
    else referrerGroup = 'Google Search';
  } else if (/((http(s)?)?:\/\/)?bing.([^/?]*)/.test(document.referrer)) {
    referrerGroup = 'Bing';
  } else if (/((http(s)?)?:\/\/)?yahoo.([^/?]*)/.test(document.referrer)) {
    referrerGroup = 'Yahoo';
  } else if (
    /((http(s)?)?:\/\/)?facebook.([^/?]*)/.test(document.referrer) ||
    /((http(s)?)?:\/\/)?fb.([^/?]*)/.test(document.referrer)
  ) {
    referrerGroup = 'Facebook';
  } else if (
    /((http(s)?)?:\/\/)?twitter.([^/?]*)/.test(document.referrer) ||
    /((http(s)?)?:\/\/)?t.co/.test(document.referrer)
  ) {
    referrerGroup = 'Twitter';
  } else if (/((http(s)?)?:\/\/)?linkedin.([^/?]*)/.test(document.referrer)) {
    referrerGroup = 'LinkedIn';
  } else if (/((http(s)?)?:\/\/)?quora.([^/?]*)/.test(document.referrer)) {
    referrerGroup = 'Quora';
  } else if (/((http(s)?)?:\/\/)?duckduckgo.([^/?]*)/.test(document.referrer)) {
    referrerGroup = 'DuckDuckGo';
  }

  mixpanel.people.set_once('Initial Referrer Domain Group', referrerGroup);
}

function getUtmTags() {
  const params = qs.parse(document.URL.split('?')[1]);

  const firstTouch = {};
  const lastTouch = {
    'utm_source [last touch]': '$direct',
    'utm_campaign [last touch]': undefined,
  };

  if (params.utm_source) {
    firstTouch['utm_source [first touch]'] = params.utm_source;
    lastTouch['utm_source [last touch]'] = params.utm_source;
  }
  if (params.utm_campaign) {
    firstTouch['utm_campaign [first touch]'] = params.utm_campaign;
    lastTouch['utm_campaign [last touch]'] = params.utm_campaign;
  }
  mixpanel.people.set_once(firstTouch);
  mixpanel.people.set(lastTouch);
  mixpanel.register(lastTouch);
}

export function getReferringInfo() {
  return {
    initialReferringDomain: mixpanel.get_property('$initial_referring_domain'),
    referringDomain: mixpanel.get_property('$referring_domain'),
    utmSource: mixpanel.get_property('utm_source'),
  };
}

function registerUserAgentWithBotIgnore() {
  const { userAgent } = navigator;
  mixpanel.register({ 'User Agent': userAgent });

  if (isBot()) {
    mixpanel.register({ $ignore: true });
  }
}

// enable only in production mode
const activeMixpanel = !isLocalhost && !isBot();

if (activeMixpanel) {
  mixpanel.init(Config.MIXPANEL.TOKEN, {
    api_host: 'https://userdata.crediblemind.com',
    ignore_dnt: true,
  });
  registerUserAgentWithBotIgnore();
  registerTouch();
  getReferrerGroup();
  getUtmTags();
}

let ipAddress;

const parseIpLookupResponse = data => {
  const response = {};
  data.split('\n').forEach(item => {
    const [key, value] = item.split('=');
    response[key] = value;
  });

  return response;
};

get(IP_LOOKUP_URL)
  .then(({ data }) => {
    const response = parseIpLookupResponse(data);
    if (response.ip) ipAddress = response.ip;
  })
  .finally(() => {
    setInterval(() => {
      get(IP_LOOKUP_URL).then(({ data }) => {
        const response = parseIpLookupResponse(data);
        if (response.ip) ipAddress = response.ip;
      });
    }, 60000);
  });

let identified = false;

const identify = async () => {
  const isAICC = await getIsAICC();
  if (isAICC) {
    await waitForAICCData();
  }

  const firebase = await getFirebase();
  const store = getStore();
  const state = store.getState();
  const auth = _get(state, 'firebase.auth');
  const aiccUser = _get(state, 'main.aiccUser', {});
  const sessionAnonymousId = _get(state, 'auth.anonymousId');
  const id =
    _get(aiccUser, 'user_id') ||
    _get(auth, 'uid') ||
    getLocalData('userIdTemp') ||
    sessionAnonymousId;
  if (DEBUG || activeMixpanel) {
    identified = true;
    if (DEBUG) {
      console.log('mixpanel.identify', { id, auth, aiccUser });
    }
    if (activeMixpanel) {
      mixpanel.identify(id);
      firebase.analytics().setUserId(id);
    }
  }
};

let brandSector;
let clientGroup;
export const setBrandSector = sector => {
  brandSector = sector;
};

export const setClientGroup = value => {
  clientGroup = value;
};

const getAuth = store => {
  const state = store.getState();
  if (!_get(state, 'firebase.auth.isLoaded')) return undefined;
  return _get(state, 'firebase.auth', {});
};

const getProfile = store => {
  const state = store.getState();
  if (!_get(state, 'firebase.profile.isLoaded')) return undefined;
  return _get(state, 'firebase.profile', {});
};

const getClinicMode = store => {
  const state = store.getState();
  return _get(state, 'main.clinicMode', false);
};

const getClientDetails = store => {
  const state = store.getState();
  return _get(state, 'main.clientDetails', {});
};

const getAICCUser = store => {
  const state = store.getState();
  return _get(state, 'main.aiccUser', {});
};

const getBirthdayFromAssessmentAnswers = store => {
  const state = store.getState();
  return _get(state, 'assessment.answers.birthdayMonthYear');
};

const getFirebaseId = async () =>
  new Promise(resolve => {
    const store = getStore();
    let auth = getAuth(store);
    if (auth !== undefined) {
      resolve(auth.uid);
    } else {
      const unsubscribe = store.subscribe(() => {
        auth = getAuth(store);
        if (auth !== undefined) {
          unsubscribe();
          resolve(auth.uid);
        }
      });
    }
  });

const getAnonymousUserId = async () =>
  new Promise(resolve => {
    const store = getStore();
    let clinicMode = getClinicMode(store);
    if (clinicMode !== undefined) {
      const storeState = store.getState();
      const sessionAnonymousId = _get(storeState, 'auth.anonymousId');
      const userIdTemp = getLocalData('userIdTemp');
      // console.log({ sessionAnonymousId, userIdTemp, clinicMode });
      if (clinicMode) {
        resolve(sessionAnonymousId || userIdTemp);
      } else {
        resolve(userIdTemp || sessionAnonymousId);
      }
    } else {
      const unsubscribe = store.subscribe(() => {
        clinicMode = getAuth(store);
        if (clinicMode !== undefined) {
          unsubscribe();
          const storeState = store.getState();
          const sessionAnonymousId = _get(storeState, 'auth.anonymousId');
          const userIdTemp = getLocalData('userIdTemp');
          if (clinicMode) {
            resolve(sessionAnonymousId || userIdTemp);
          } else {
            resolve(userIdTemp || sessionAnonymousId);
          }
        }
      });
    }
  });

const parseIsInternalData = (profile, clientDetails) =>
  profile.role === 'admin' ||
  profile.role === 'contentAdmin' ||
  getIsClientAdmin(profile, clientDetails) ||
  (profile.email || '').includes('@crediblemind.co');

const getIsInternal = async () =>
  new Promise(resolve => {
    const store = getStore();
    let profile = getProfile(store);
    let clientDetails = getClientDetails(store);
    if (profile !== undefined && clientDetails !== undefined) {
      const isInternal = parseIsInternalData(profile, clientDetails);
      resolve(isInternal);
    } else {
      const unsubscribe = store.subscribe(() => {
        profile = getProfile(store);
        clientDetails = getClientDetails(store);
        if (profile !== undefined && clientDetails !== undefined) {
          unsubscribe();
          const isInternal = parseIsInternalData(profile, clientDetails);
          resolve(isInternal);
        }
      });
    }
  });

const parseDemographicsData = profile => {
  const data = {};
  if (profile.gender) {
    data.userGender = profile.gender;
  }
  if (profile.genderOther) {
    data.userGenderOther = profile.genderOther;
  }
  if (profile.genderLGBTQp) {
    data.userGenderLGBTQp = profile.genderLGBTQp;
  }
  if (profile.dob) {
    data.userAge = profile.dob;
  }
  if (profile.birthdayMonthYear) {
    const userAgeInfo = getParsedUserAgeInfo(profile.birthdayMonthYear);
    data.userBirthdayMonth = userAgeInfo.userBirthdayMonth;
    data.userParsedAge = userAgeInfo.userParsedAge;
  }
  if (profile.industry) {
    data.userIndustry = profile.industry;
  }
  if (profile.isEmpty) {
    data.userInsurance = getLocalData('insurance');
  } else if (Array.isArray(profile.insurance))
    data.userInsurance = profile.insurance.join(',');
  else data.userInsurance = profile.insurance;

  return data;
};

const parseAssessmentDemographicsData = userId => {
  const data = {};

  const stringified = getLocalData('assessmentDemographics');
  // Check if stringified is a valid JSON string
  try {
    const assessmentDemographics = stringified ? JSON.parse(stringified) : {};
    if (assessmentDemographics.id === userId) {
      if (assessmentDemographics.gender) {
        data.userGender = assessmentDemographics.gender;
      }
      if (assessmentDemographics.genderOther) {
        data.userGenderOther = assessmentDemographics.genderOther;
      }
      if (assessmentDemographics.genderLGBTQp) {
        data.userGenderLGBTQp = assessmentDemographics.genderLGBTQp;
      }
      if (assessmentDemographics.dob) {
        data.userAge = assessmentDemographics.dob;
      }
      if (assessmentDemographics.birthdayMonthYear) {
        const userAgeInfo = getParsedUserAgeInfo(
          assessmentDemographics.birthdayMonthYear,
        );
        data.userBirthdayMonth = userAgeInfo.userBirthdayMonth;
        data.userParsedAge = userAgeInfo.userParsedAge;
      }
      if (assessmentDemographics.industry) {
        data.userIndustry = assessmentDemographics.industry;
      }
      if (assessmentDemographics.insurance) {
        data.userInsurance = assessmentDemographics.insurance;
      }
    }
  } catch (e) {
    console.error('Failed to parse assessmentDemographics data:', e);
  }

  return data;
};

const getDemographicsData = async () =>
  new Promise(resolve => {
    const store = getStore();
    let profile = getProfile(store);
    if (profile !== undefined && !profile.isEmpty) {
      const data = parseDemographicsData(profile);
      resolve(data);
    } else {
      const unsubscribe = store.subscribe(() => {
        profile = getProfile(store);
        if (profile !== undefined && !profile.isEmpty) {
          unsubscribe();
          const data = parseDemographicsData(profile);
          resolve(data);
        }
      });

      const birthdayMonthYear = getBirthdayFromAssessmentAnswers(store);
      if (!birthdayMonthYear) {
        resolve();
      }
      const data = getParsedUserAgeInfo(birthdayMonthYear);
      resolve(data);
    }
  });

const getIsAICC = async () =>
  new Promise(resolve => {
    const store = getStore();
    let clientDetails = getClientDetails(store);
    if (clientDetails !== undefined) {
      const isAICC = _get(clientDetails, 'aicc', false);
      resolve(isAICC);
    } else {
      const unsubscribe = store.subscribe(() => {
        clientDetails = getClientDetails(store);
        if (clientDetails !== undefined) {
          unsubscribe();
          const isAICC = _get(clientDetails, 'aicc', false);
          resolve(isAICC);
        }
      });
    }
  });

const getAICCData = async () =>
  new Promise(resolve => {
    const store = getStore();
    let aiccUser = getAICCUser(store);
    if (aiccUser !== undefined) {
      const data = {
        organizationName: _get(aiccUser, 'organization_name'),
        institutionName: _get(aiccUser, 'institution_name'),
        studentId: _get(aiccUser, 'student_id'),
      };
      resolve(data);
    } else {
      const unsubscribe = store.subscribe(() => {
        aiccUser = getAICCUser(store);
        if (aiccUser !== undefined) {
          unsubscribe();
          const data = {
            organizationName: _get(aiccUser, 'organization_name'),
            institutionName: _get(aiccUser, 'institution_name'),
            studentId: _get(aiccUser, 'student_id'),
          };
          resolve(data);
        }
      });
    }
  });

const getSAMLData = async () =>
  new Promise(resolve => {
    const parseSAMLData = profile => _get(profile, 'samlData', {});
    const store = getStore();
    let profile = getProfile(store);
    if (profile !== undefined) {
      const data = parseSAMLData(profile);
      resolve(data);
    } else {
      const unsubscribe = store.subscribe(() => {
        profile = getProfile(store);
        if (profile !== undefined) {
          unsubscribe();
          const data = parseSAMLData(profile);
          resolve(data);
        }
      });
    }
  });

const parseCustomData = profile => {
  const data = {};
  if (_get(profile, 'custom.customConsent')) {
    data.customConsent = _get(profile, 'custom.customConsent');
  }

  return data;
};

const getCustomData = async () =>
  new Promise(resolve => {
    const store = getStore();
    let profile = getProfile(store);
    if (profile !== undefined) {
      const data = parseCustomData(profile);
      resolve(data);
    } else {
      const unsubscribe = store.subscribe(() => {
        profile = getProfile(store);
        if (profile !== undefined) {
          unsubscribe();
          const data = parseCustomData(profile);
          resolve(data);
        }
      });
    }
  });

const waitForAICCData = async () =>
  new Promise(resolve => {
    const store = getStore();
    let user = getAICCUser(store);
    if (!_isEmpty(user)) {
      resolve(true);
    } else {
      const unsubscribe = store.subscribe(() => {
        user = getAICCUser(store);
        if (!_isEmpty(user)) {
          unsubscribe();
          resolve(true);
        }
      });
    }
  });

const getIsSignedIn = async () =>
  new Promise(resolve => {
    const store = getStore();
    let profile = getProfile(store);
    // console.log('getIsSignedIn', profile);
    if (profile !== undefined) {
      const isSignedIn = !profile.isEmpty;
      resolve(isSignedIn);
    } else {
      const unsubscribe = store.subscribe(() => {
        profile = getProfile(store);
        // console.log('getIsSignedIn', profile);
        if (profile !== undefined) {
          unsubscribe();
          const isSignedIn = !profile.isEmpty;
          resolve(isSignedIn);
        }
      });
    }
  });

const MixpanelActions = {
  // init: token => {
  //   if (activeMixpanel) {
  //     mixpanel.init(token);
  //   }
  // },
  identify,
  alias: firebaseId => {
    const id = getLocalData('userIdTemp');
    if (DEBUG) console.log('mixpanel.alias', firebaseId, id);
    if (activeMixpanel) mixpanel.alias(firebaseId, id);
  },
  track: async (name, props) => {
    if (!identified) {
      await identify();
    }

    const currentUrl = window.location.href;
    const basename = getBasename();

    const { referrer } = document;
    const store = getStore();
    const heroVariant = emitter.getActiveVariant('hero-variant');
    const assessmentResultsVariant = emitter.getActiveVariant(
      'assessment-results-lists',
    );
    const firstTimeUser = getLocalData('firstTimeUser');
    let firstTimeUserFinal = 'Returning';
    if (_isUndefined(firstTimeUser)) {
      firstTimeUserFinal = 'Undefined';
    } else if (firstTimeUser === 'true') {
      firstTimeUserFinal = 'New';
    }
    const brand = getLocalData('brand');
    const clinicMode = getClinicMode(store);
    const demographicsData = await getDemographicsData();
    const firebaseId = await getFirebaseId();
    const anonymousId = await getAnonymousUserId();
    const assessmentDemographicsData = parseAssessmentDemographicsData(
      anonymousId,
    );
    const isInternal = await getIsInternal();
    const isSignedIn = await getIsSignedIn();
    const isAICC = await getIsAICC();
    const aiccData = isAICC ? await getAICCData() : {};
    const samlData = await getSAMLData();
    const customData = await getCustomData();
    let language = 'Undefined';
    if (isLocalStorageAllowed()) {
      language = localStorage.getItem('language');
      if (language === null) language = 'english';
    }

    const referrerData = {};
    if (isInternal) {
      referrerData.referrer = null;
    }

    const embeddedContainerUrl = getParentShareUrl();
    const embeddedSubBrand = getSubBrand();
    const isWebViewMode = isWebView();

    let finalProps = {
      ...props,
      brand: brand || 'none',
      brandSector,
      clientGroup,
      firstTimeUser: firstTimeUserFinal,
      homeVariant: heroVariant || 'none',
      // 'Experiment name': 'Assessment Results Design',
      // 'Variant name': assessmentResultsDesignVariant || 'none',
      assessmentResultsVariant: assessmentResultsVariant || 'none',
      isInternal,
      isSignedIn,
      isAICC,
      initial_id: getLocalData('userIdTemp'),
      publicMode: clinicMode,
      firebase_id: firebaseId,
      user_ip: ipAddress,
      ...aiccData,
      ...demographicsData,
      ...(isSignedIn ? {} : assessmentDemographicsData),
      ...samlData,
      ...customData,
      ...referrerData,
      language,
      ...(embeddedContainerUrl ? { containerUrl: embeddedContainerUrl } : {}),
      ...(embeddedSubBrand ? { subBrand: embeddedSubBrand } : {}),
      $current_url: currentUrl,
      $referrer: referrer,
      ...(isWebViewMode ? { isWebView: isWebViewMode } : {}),
    };

    if (basename) {
      finalProps = Object.entries(finalProps).reduce((acc, [key, value]) => {
        acc[key] = typeof value === 'string' ? removeBasename(value) : value;
        return acc;
      }, {});
    }

    if (!finalProps.name) finalProps.name = name;

    if (DEBUG) console.log('mixpanel.track', name, finalProps);
    if (activeMixpanel) {
      mixpanel.track(name, finalProps);
      GA4.track(name, finalProps);
      FirebaseAnalyticsService.addEvent(name, finalProps);
    }
  },
  // session_end: userId => {
  //   if (activeMixpanel) {
  //     const token = Config.MIXPANEL.TOKEN;
  //     const startTime =
  //       // eslint-disable-next-line dot-notation
  //       mixpanel['persistence']['props']['__timers']['Session ended'];
  //     let duration = new Date().getTime() - startTime;
  //     duration = parseFloat((duration / 1000).toFixed(3));
  //     const data = {
  //       event: 'Session ended',
  //       properties: {
  //         ...mixpanel._.info.properties(),
  //         ...mixpanel.persistence.properties(),
  //         token,
  //         distinct_id: userId,
  //         $duration: duration,
  //         name: 'Session ended',
  //       },
  //     };
  //     const encodedData = btoa(JSON.stringify(data));

  //     const req = new XMLHttpRequest();
  //     req.open(
  //       'GET',
  //       `https://api.mixpanel.com/track/?data=${encodedData}&ip=1&_=${Date.now()}`,
  //       false,
  //     );
  //     const headers = mixpanel.get_config('xhr_headers');
  //     _forEach(headers, (headerValue, headerName) => {
  //       req.setRequestHeader(headerName, headerValue);
  //     });
  //     req.withCredentials = true;
  //     req.send(null);
  //   }
  // },
  // time_event: name => {
  //   if (activeMixpanel) mixpanel.time_event(name);
  // },
  people: {
    set: props => {
      const brand = getLocalData('brand');
      if (DEBUG) console.log('mixpanel.people.set', props);
      if (activeMixpanel) {
        mixpanel.people.set(props);
        FirebaseAnalyticsService.setUserProfile({ ...props, brand });
      }
    },
  },
  reset: () => {
    if (DEBUG || activeMixpanel) {
      identified = false;
      if (activeMixpanel) mixpanel.reset();
    }
  },
  forceIdentify: () => {
    if (DEBUG || activeMixpanel) {
      identified = false;
    }
  },
};

export default MixpanelActions;
