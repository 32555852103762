/**
 *
 * useNPSPreConditions
 *
 */

import { useEffect, useRef, useState } from 'react';
import update from 'immutability-helper';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { meetNPSPreconditions } from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectNPSPreconditions,
  makeSelectSiteCopy,
} from 'containers/Main/selectors';
import useIsAuthPath from 'components/Hooks/useIsAuthPath';
import { DEFAULT_CONFIG } from './constants';

function useNPSPreConditions() {
  const dispatch = useDispatch();
  const [hasMinutesConditions, setMinutesConditions] = useState(false);
  const [hasNavigationConditions, setNavigationConditions] = useState(false);
  const numNavigations = useRef(0);
  const lastURL = useRef(null);
  const location = useLocation();
  const preconditions = useSelector(makeSelectNPSPreconditions());
  const siteCopy = useSelector(makeSelectSiteCopy());
  const clientDetails = useSelector(makeSelectClientDetails());
  const isAuthPath = useIsAuthPath();

  const npsSiteCopy = _get(
    siteCopy.filter(el => el.slug === 'nps')[0],
    'pageCopy',
    {},
  );
  const npsClient = _get(clientDetails, 'metadata.nps', {});
  const finalNPS = update(npsSiteCopy, {
    $merge: npsClient,
  });

  const preConditionMinutes = _get(
    finalNPS,
    'config.preConditionMinutes',
    DEFAULT_CONFIG.preConditionMinutes,
  );
  const preConditionNumNavigation = _get(
    finalNPS,
    'config.preConditionNumNavigation',
    DEFAULT_CONFIG.preConditionNumNavigation,
  );

  useEffect(() => {
    if (!preconditions) {
      let sessionTime = 0;

      const interval = setInterval(() => {
        if (document.visibilityState === 'visible' && !isAuthPath) {
          sessionTime += 5000;

          if (sessionTime >= preConditionMinutes * 60 * 1000) {
            setMinutesConditions(true);
            clearInterval(interval);
          }
        }
      }, 5000);
      return () => clearInterval(interval);
    }

    return () => null;
  }, [preconditions]);

  useEffect(() => {
    if (lastURL.current !== location.pathname) {
      lastURL.current = location.pathname;
      numNavigations.current += 1;
    }

    if (numNavigations.current >= preConditionNumNavigation)
      setNavigationConditions(true);
  }, [location]);

  useEffect(() => {
    if (hasMinutesConditions || hasNavigationConditions)
      dispatch(meetNPSPreconditions());
  }, [hasMinutesConditions, hasNavigationConditions]);

  return null;
}

export default useNPSPreConditions;
