import { useLocation } from 'react-router-dom';

const authenticationPaths = [
  '/login',
  '/logincm',
  '/signup',
  '/signupcm',
  '/signup/data',
  '/signup/email',
  '/signup/verify',
  '/forgot_password',
  '/2fa',
  '/new_password',
  '/auth',
];

const useIsAuthPath = () => {
  const location = useLocation();

  return authenticationPaths.includes(location.pathname);
};

export default useIsAuthPath;
