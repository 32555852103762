import _isNull from 'lodash/isNull';
import { getLocalData } from 'utils/localDataStore';
import { isLocalhost } from 'utils/networkUtils';
import { getSubdomain } from 'utils/stringUtils';

export const DEFAULT_BRAND = 'benovo';

export const getBrandValue = () => {
  const subdomain = getSubdomain();
  if (isLocalhost || _isNull(subdomain) || subdomain === 'development') {
    const localBrand = getLocalData('brand');
    return localBrand && localBrand !== 'none' ? localBrand : DEFAULT_BRAND;
  }

  return subdomain;
};
