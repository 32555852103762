import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useIsEmailVerified from 'components/Hooks/useIsEmailVerified';
import useIsAuthPath from 'components/Hooks/useIsAuthPath';

function useAccountVerification() {
  const history = useHistory();
  const { verified, isEnabled, isRequired } = useIsEmailVerified();
  const isAuthenticationPage = useIsAuthPath();

  useEffect(() => {
    if (isEnabled && isRequired && !verified && !isAuthenticationPage) {
      history.push('/signup/verify');
    }
  }, [history, isAuthenticationPage, isEnabled, isRequired, verified]);
}

export default useAccountVerification;
