import { useEffect, useRef } from 'react';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { processing } from 'containers/Auth/actions';
import { makeSelectProfile } from 'containers/Auth/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { post } from 'utils/api';
import Config from 'utils/getEnvConfig';
import { useFirebase } from 'react-redux-firebase';

const {
  LAMBDA: {
    CUSTOM_AUTHENTICATION_HELPER: { PROVIDERS_URL, API_KEY },
  },
} = Config;

function useEligibilityProvider() {
  const latestMids = useRef();
  const clientDetails = useSelector(makeSelectClientDetails());
  const profile = useSelector(makeSelectProfile());
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const eligibilityConfig = _get(
    clientDetails,
    'customAuthentication.eligibility',
    {},
  );
  const isEnabled = _get(eligibilityConfig, 'enabled', false);
  const isSideDoorUser =
    _get(profile, 'providerData.0.providerId') === 'password';
  let mids = '';
  if (isEnabled) {
    if (isSideDoorUser) {
      mids = _get(profile, 'custom.mid', []).join(',');
    } else {
      mids = _get(profile, 'samlData.MID');
    }
  }

  useEffect(() => {
    if (mids && mids !== latestMids.current) {
      latestMids.current = mids;

      const getEligibleProviders = async () => {
        try {
          dispatch(processing(true));

          const responses = await Promise.all(
            mids.split(',').map(mid =>
              post(
                PROVIDERS_URL,
                { mid },
                {
                  headers: {
                    'x-api-key': API_KEY,
                  },
                },
              ),
            ),
          );

          const customEligibility = {};
          _flatten(responses.map(response => response.data)).forEach(
            provider => {
              Object.keys(provider).forEach(key => {
                if (customEligibility[key]) {
                  customEligibility[key].push(...provider[key]);
                } else {
                  customEligibility[key] = [...provider[key]];
                }
              });
            },
          );

          if (
            !_isEqual(_get(profile, 'custom.eligibility'), customEligibility)
          ) {
            await firebase.updateProfile({
              custom: {
                eligibility: customEligibility,
              },
            });
          }
        } finally {
          dispatch(processing(false));
        }
      };

      getEligibleProviders();
    }
  }, [dispatch, firebase, mids, profile]);

  useEffect(() => {
    if (isEnabled && location.pathname !== '/auth/eligibility') {
      if (
        profile.isLoaded &&
        !profile.isEmpty &&
        _get(profile, 'providerData.0.providerId') === 'password'
      ) {
        const vendors = _get(profile, 'custom.eligibility.vendorCode', []);
        if (vendors.length === 0) {
          history.push('/auth/eligibility');
        }
      }
    }
  }, [history, isEnabled, location, profile]);
}

export default useEligibilityProvider;
