import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { apiCatch } from 'utils/sentryApiCatch';

export function post(endpoint, payload, config, rawError = false) {
  const postFunc = axios.post(endpoint, payload, config);

  return postFunc
    .then(response => response)
    .catch(rawError ? rawErrorHandler : errorHandler);
}

export function patch(endpoint, payload, config) {
  const patchFunc = axios.patch(endpoint, payload, config);

  return patchFunc.then(response => response).catch(errorHandler);
}

export function update(endpoint, payload) {
  const putFunc = axios.put(endpoint, payload);

  return putFunc.then(response => response).catch(errorHandler);
}

export function get(endpoint, config) {
  const getFunc = axios.get(endpoint, config);
  return getFunc.then(response => response).catch(errorHandler);
}

export function remove(endpoint) {
  const getFunc = axios.delete(endpoint);

  return getFunc.then(response => response).catch(errorHandler);
}

function errorHandler(error) {
  apiCatch(error);
  if (_isEmpty(_get(error, 'response'))) {
    throw error;
  } else {
    throw _get(error, 'response.data');
  }
}

function rawErrorHandler(error) {
  apiCatch(error);
  throw error;
}

export function maybeContentfulReviewStatus() {
  const hostname = window && window.location && window.location.hostname;
  if (
    [
      'crediblemind.com',
      'd2n4q77awvr9gf.cloudfront.net',
      'd1ueen3wtv2jy7.cloudfront.net',
    ].includes(hostname)
  )
    return { 'fields.reviewStatus[in]': 'Accepted,Peer Review,Retired' };

  const subdomainRegex = /[0-9a-zA-Z]+\.crediblemind\.com/;
  if (subdomainRegex.test(hostname))
    return { 'fields.reviewStatus[in]': 'Accepted,Peer Review,Retired' };

  return null;
}
